<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="类型">
              <a-select v-model="queryParam.business_type" placeholder="请选择" allow-clear>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.business_type">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select placeholder="请选择" style="width: 100%" v-model="queryParam.result" allow-clear>
                <a-select-option v-for="(value, key) in this.$Dictionaries.approval_result" :key="key" :value="key">
                  {{ value }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="是否结束">
              <a-select style="width: 100%" v-model="queryParam.is_completed" placeholder="请选择" allow-clear>
                <a-select-option :value="true">是</a-select-option>
                <a-select-option :value="false">否</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :pageNum="pageNum"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleWatch(record)">查看</a>
            <a-divider type="vertical"/>
            <a @click="handleBill(record)">关联账单</a>
          </template>
        </span>
      </s-table>
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'

import { getApproveToDoAllList } from '@/api/design'
export default {
  name: 'TableShenPiToDoList',
  components: {
    STable
  },
  data () {
    return {
      pageNum: 1,
      loading: false,
      visible: false,
      confirmLoading: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '类型',
          dataIndex: 'business_type',
          width: 150,
          customRender: (text) => this.$Dictionaries.business_type[text] || '无'
        },
        {
          title: '名称',
          dataIndex: 'approval_name'
        },
        {
          title: '进度',
          width: 100,
          dataIndex: 'process_node',
          customRender: (text) => text === '0-0' ? '' : text
        },
        {
          title: '创建时间',
          dataIndex: 'create_time'
        },
        {
          title: '结束时间',
          dataIndex: 'last_update'
        },
        {
          title: '状态',
          dataIndex: 'result',
          align: 'center',
          customRender: (text) => this.$Dictionaries.approval_result[text] || '无'
        },
        {
          title: '是否结束',
          dataIndex: 'is_completed',
          align: 'center',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      dispatchBill: {
        'water_bill': '/water/index/',
        'electricity_bill': '/electricity/payment/',
        'rent_bill': '/rent_bill/index/',
        'property_bill': '/property_bill/index/',
        'contract_bill': '/rent_contract/index/',
        'guarantee_bill': '/guarantee/index/'
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return getApproveToDoAllList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('ApprovalTodoAllCurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('ApprovalTodoAllCurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    handleWatch (item) {
      console.log(item)
      // sessionStorage.setItem('ApprovalTodoAllCurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({
        path: '/users/approval/todo_bill/' + item.data_id + '/',
        query: {
          type: item.business_type
        }
      })
    },
    handleBill (item) {
      console.log(item)
      // sessionStorage.setItem('ApprovalTodoAllCurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({
        path: this.dispatchBill[item.business_type] + item.data_id + '/'
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
